<template>
  <down-up-popup :title="title" class="sort-popup" @close="$emit('close')">
    <template v-if="items.length > 0">
      <ul
        slot="body"
        class="select_list_box"
        :style="height ? `height: ${height}` : ''"
      >
        <li
          v-for="item in items"
          :key="item.label"
          @click="$emit('select', item)"
        >
          <button v-if="item.key == item_sort_key" type="button">
            <strong>
              {{ item.label }}
            </strong>
          </button>
          <button v-else type="button">
            {{ item.label }}
          </button>
        </li>
      </ul>
    </template>

    <template v-if="items.length === 0" slot="body">
      <div class="no-text">
        {{ noText }}
      </div>
    </template>

    <template slot="footer">
      <span />
    </template>
  </down-up-popup>
</template>

<script>
import DownUpPopup from '@/components/popups/DownUpPopup.vue';
import {
  addBodyOverflowHidden,
  removeBodyOverflowHidden,
} from '../../utils/CommonUtils';

export default {
  components: { DownUpPopup },
  props: ['items', 'title', 'noText', 'height'],
  data() {
    return {
      item_sort_key: localStorage.getItem('item_sort_key'),
    };
  },
  created() {
    addBodyOverflowHidden();
  },
  destroyed() {
    removeBodyOverflowHidden();
  },
};
</script>

<style>
.no-text {
  text-align: center;
}

.select_list_box {
  overflow: scroll;
}

.sort-popup .pop_body {
  padding: 0;
}
</style>
